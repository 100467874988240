import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router';
import SearchIcon from '../../Icon/SearchIcon';
import InfoComponent from '../InfoComponent';
import { Overlay, OverlayTrigger, Tooltip } from 'react-bootstrap';
import EmployeeCreationModal from '../Home/EmployeeCreationModal';
import ExcelDownload from '../ExcelDownload';

const EmployeeTable = ({ data }) => {
    let [filterredData, setFilteredData] = useState()
    console.log(data);
    useEffect(() => {
        if (data)
            setFilteredData(data)
    }, [data])
    let navigate = useNavigate()
    let filterDataFuc = (e) => {
        let value = e.target.value.toLowerCase()
        if (value != '') {
            let filteringData = [...data].filter((obj) =>
                (obj.empId && obj.empId.toLowerCase().indexOf(value) != -1) ||
                (obj.emp_name && obj.emp_name.toLowerCase().indexOf(value) != -1) ||
                (obj.emp_type && obj.emp_type.toLowerCase().indexOf(value) != -1) ||
                (obj.email && obj.email.toLowerCase().indexOf(value) != -1) ||
                (obj.phone && String(obj.phone).toLowerCase().indexOf(value) != -1)
            )
            setFilteredData(filteringData)
        } else {
            setFilteredData(data)
        }
    }
    const renderTooltip = (porps) => (
        <Tooltip id="button-tooltip" className='  ' {...porps} > Search the orders by Employee ID , Name , Mail ,Phone , Employee Type  </Tooltip>
    );

    return (
        <div>
            <section className='my-2 flex items-center justify-between flex-wrap gap-3 ' >
                <OverlayTrigger placement="top" delay={{ show: 150, hide: 200 }}
                    overlay={renderTooltip} >
                    <div className=' border-2 bg-white rounded w-fit flex gap-2 items-center px-2 relative ' >
                        <input type="text" onChange={filterDataFuc} placeholder='Search...'
                            className='p-2 rounded outline-none ' />
                        <SearchIcon />
                        {/* <span className='absolute -right-2 -top-5 ' >
                            <InfoComponent size={12} content="Search the orders by Employee ID , Name , Mail ,Phone , Employee Type " />
                        </span> */}
                    </div>
                </OverlayTrigger>
                <EmployeeCreationModal />
            </section>
            <main className='table-responsive h-[60vh] px-0 overflow-y-scroll rounded w-full tablebg ' >
                <table className='w-full relative ' >
                    <tr className='sticky bg-blue-800 text-white top-0 bgclr ' >
                        <th> SI NO </th>
                        <th>Emp ID </th>
                        <th>Name </th>
                        <th>Employment type </th>
                        <th> Mail Id </th>
                        <th> Phone </th>
                        <th>Status </th>

                    </tr>
                    {
                        filterredData && filterredData.map((obj, index) => (
                            <tr onClick={() => navigate(`/employee/${obj._id}`)}
                                className={` ${index % 2 == 0 ? ' ' : ' rowbg '} cursor-pointer hover:bg-blue-50 ${obj.status != 'active' && 'bg-red-50'} `} >
                                <td className='colbg ' >{index + 1} </td>
                                <td>{obj.empId} </td>
                                <td className='colbg ' > {obj.emp_name} </td>
                                <td>{obj.emp_type} </td>
                                <td className='colbg ' >{obj.email} </td>
                                <td>{obj.phone} </td>
                                <td className='colbg ' >{obj.status} </td>
                            </tr>
                        ))
                    }

                </table>
            </main>
            <div className='my-2 ' >

                {filterredData && <ExcelDownload
                    data={ [...filterredData].map((obj, index) => {
                        return {
                            "Employee ID": obj.empId,
                            "Employee name": obj.emp_name,
                            "Email": obj.email,
                            "Phone": obj.phone,
                            "Employeement": obj.emp_type,
                            "Status": obj.status
                        }
                    }
                    )} name='EmployeeReport' />}
            </div>
            {/*  */}
        </div>
    )
}

export default EmployeeTable