import axios from 'axios'
import React, { createContext, useEffect, useState } from 'react'
import { port } from '../App'
import CryptoJS from 'crypto-js';

export const Store = createContext()
const Storage = ({ children }) => {
    let [active, setactive] = useState()
    let [dasactive, setDasActive] = useState()
    let [user, setUser] = useState()
    let [allorders, setAllOrders] = useState()
    let [allemp, setAllEmp] = useState()
    let [allMachine, setAllMachine] = useState()
    let [allStages, setAllStages] = useState()
    let getAllStageNames = () => {
        axios.get(`${port}/api/stageName`).then((response) => {
            console.log(response.data);
            setAllStages(response.data)
        }).catch((error) => {
            console.log(error);
        })
    }
    let getUser = () => {
        let id = (sessionStorage.getItem('gebba_user'))
        if (id)
            axios.get(`${port}/api/user/${id}`).then((response) => {
                setUser(response.data)
                console.log(response.data, "user");
            }).catch((error) => {
                console.log(error);
            })
    }
    let getAllMachine = () => {
        axios.get(`${port}/api/machinery`).then((response) => {
            setAllMachine(response.data)
            console.log(response.data, "machine");
        }).catch((error) => {
            console.log(error);
        })
    }
    function getProperDate(date) {
        const now = new Date(date);
        const year = now.getFullYear();
        const month = String(now.getMonth() + 1).padStart(2, '0');
        const day = String(now.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    }
    let getAllEmp = () => {
        axios.get(`${port}/api/user`).then((response) => {
            setAllEmp(response.data)
            console.log(response.data, "allemp");
        }).catch((error) => {
            console.log(error);
        })
    }
    let getAllOrders = () => {
        axios.get(`${port}/api/order`).then((response) => {
            console.log(response.data, 'orders');
            setAllOrders(response.data)
        }).catch((error) => {
            console.log(error);
        })
    }

    let readableTime = (times) => {
        const date = new Date(times);
        // Convert to IST by adding 5.5 hours
        const istDate = new Date(date.getTime() );

        // Extract day, month, year, hours, minutes
        const day = String(istDate.getDate()).padStart(2, '0');
        const month = String(istDate.getMonth() + 1).padStart(2, '0'); // Months are 0-indexed
        const year = String(istDate.getFullYear()).slice(-2); // Get last two digits of the year

        let hours = istDate.getHours();
        const minutes = String(istDate.getMinutes()).padStart(2, '0');
        const ampm = hours >= 12 ? 'PM' : 'AM';
        hours = hours % 12 || 12; // Convert to 12-hour format

        return `${day}-${month}-${year} ${hours}:${minutes} ${ampm}`;
    }
    useEffect(() => {
        getAllMachine()
        getAllStageNames()
        if (!user)
            getUser()
    }, [])
    let encryptWord = (word) => {
        return CryptoJS.AES.encrypt(word, 'jerold').toString();
    }
    let decryptWord = (word) => {
        const bytes = CryptoJS.AES.decrypt(word, 'jerold')
        return bytes.toString(CryptoJS.enc.Utf8);
    }
    let value = {
        getProperDate, getAllMachine, allMachine, encryptWord, decryptWord,
        allStages, setAllStages, getAllStageNames,
        active, setactive, getUser, user, dasactive, readableTime,
        setDasActive, getAllEmp, allemp, getAllOrders, allorders
    }

    return (
        <Store.Provider value={value} >
            {children}
        </Store.Provider>
    )
}

export default Storage